<template>
    <div class="mt-4">
        <v-row>
            <v-col cols=12 class="text-right">
                <v-btn text color="primary" :to="{name: 'Admin'}">
                    <span class="secondary--text">
                        {{ $t('admin.backToAdminPanel') }}
                    </span>
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>